<template>
  <div>
    <v-row>
      <v-layout row-wrap>
       
        <v-flex xs3 md3>
          <v-select
            v-model="month"
            :items="months"
            label="الشهر"
            persistent-hint
            required
          ></v-select>
        </v-flex>

        <v-flex xs3 md3>
          <v-select
            v-model="year"
            :items="years"
            label="السنة"
            persistent-hint
            required
          ></v-select>
        </v-flex>
      </v-layout>
        </v-row>
    <v-data-table
      :headers="headers"
      :items="cement"
      sort-by="name"
      class="elevation-1 mt-3"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr class="overflow-y-auto" style="max-height: 300px">
            <td>Daily Volume</td>
            <td class="text-xs-left headcol">{{ DailyVolume[0].YCC }}</td>

            <td class="text-xs-left">{{ DailyVolume[0].RCC }}</td>
            <td class="text-xs-left">{{ DailyVolume[0].SCC }}</td>
            <td class="text-xs-left">{{ DailyVolume[0].CCC }}</td>
            <td class="text-xs-left">{{ DailyVolume[0].GCC }}</td>
            <td class="text-xs-left">{{ DailyVolume[0].other }}</td>
            <td class="text-xs-left">{{ totalSales }}</td>
          </tr>
          <tr class="overflow-y-auto" style="max-height: 300px">
            <td>Market Share</td>
            <td class="text-xs-left headcol">{{ MarketShare[0].YCC }}</td>

            <td class="text-xs-left">{{ MarketShare[0].RCC }}</td>
            <td class="text-xs-left">{{ MarketShare[0].SCC }}</td>
            <td class="text-xs-left">{{ MarketShare[0].CCC }}</td>
            <td class="text-xs-left">{{ MarketShare[0].GCC }}</td>
            <td class="text-xs-left">{{ MarketShare[0].other }}</td>
          </tr>
          <tr class="overflow-y-auto" style="max-height: 300px">
            <td>Price Avg</td>
            <td class="text-xs-left headcol">{{ AvgPrice[0].YCC }}</td>

            <td class="text-xs-left">{{ AvgPrice[0].RCC }}</td>
            <td class="text-xs-left">{{ AvgPrice[0].SCC }}</td>
            <td class="text-xs-left">{{ AvgPrice[0].CCC }}</td>
            <td class="text-xs-left">{{ AvgPrice[0].GCC }}</td>
            <td class="text-xs-left">{{ AvgPrice[0].other }}</td>
          </tr>
        </tbody>
      </template>

      <template slot="body.append">
        <tr class="pink--text">
          <th class="title">Totals</th>
          <th class="title">{{ total }}</th>
        </tr>
      </template>
    </v-data-table>

    <v-divider></v-divider>
    <v-container grid-list-md>
      <v-layout wrap>
        <v-flex xs12 md12>
          <v-hover open-delay="100" close-delay="100">
            <v-card class="mx-auto text-center">
              <v-card-title
                class="justify-center"
                style="
                  background-color: #006a4e;

                  color: aliceblue;
                "
              >
                Area Details</v-card-title
              >

              <v-layout row-wrap>
                <v-flex xs3 md3>
                  <v-autocomplete
                    :items="Allarea"
                    v-model="AreaId"
                    color="white"
                    item-text="name"
                    label="المنطقة"
                    return-object
                    required
                  ></v-autocomplete>
                </v-flex>
              </v-layout>

              <v-card-text>
                {{ AreaData }}
                <v-layout row-wrap>
                  <v-flex>
                    <apexchart
                      type="pie"
                      :series="AreaData"
                      width="380"
                      :options="chartOptions"
                    ></apexchart>
                  </v-flex>
                  <v-flex class="ko">
                    <apexchart
                      type="pie"
                      :series="series"
                      width="380"
                      :options="Options"
                    ></apexchart>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-container>
    <v-card>
      <v-card-title
        class="justify-center"
        style="
          background-color: #006a4e;

          color: aliceblue;
        "
      >
        Market Details</v-card-title
      >
      <!-- <v-layout row-wrap>
        <v-flex xs3 md3>
          <v-select
            v-model="period"
            :items="periodOption"
            label="مدة التقرير"
            persistent-hint
            required
          ></v-select>
        </v-flex>
      </v-layout> -->
      <v-container style="text-align: center">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headersMarketDetails"
              :items="itemMarketDetails"
              sort-by="name"
              class="elevation-1"
            >
              <template v-slot:item.lastVisit="{ item }">
                <span>{{ new Date(item.lastVisit).toLocaleString() }}</span>
              </template>
              <template v-slot:item.productName="{ item }">
                <tr
                  v-for="item in item.cementPerCompanies"
                  :key="item.productName"
                >
                  <td>{{ item.productName }}</td>
                </tr>
              </template>
              <template v-slot:item.quantity="{ item }">
                <tr
                  v-for="item in item.cementPerCompanies"
                  :key="item.quantity"
                >
                  <td>{{ item.quantity }}</td>
                </tr>
              </template>
              <template v-slot:item.companyName="{ item }">
                <tr
                  v-for="item in item.cementPerCompanies"
                  :key="item.companyName"
                >
                  <td>{{ item.companyName }}</td>
                </tr>
              </template>
              <template v-slot:item.price="{ item }">
                <tr v-for="item in item.cementPerCompanies" :key="item.price">
                  <td>{{ item.price }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "market-segmentation",

  data() {
    return {
      AreaId: {
        name: "southern region",
        id: 90,
      },
      Allarea: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [],
      AreaData: [],
      AvgPrice: [
        {
          YCC: 0,
          CCC: 0,
          RCC: 0,
          GCC: 0,
          SCC: 0,
          other: 0,
          total: 0,
        },
      ],
      MarketShare: [
        {
          YCC: 0,
          CCC: 0,
          RCC: 0,
          GCC: 0,
          SCC: 0,
          other: 0,
          total: 0,
        },
      ],
      DailyVolume: [
        {
          YCC: 0,
          CCC: 0,
          RCC: 0,
          GCC: 0,
          SCC: 0,
          other: 0,
          total: 0,
        },
      ],
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
      },
      Options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
      },
      year: "",
      month: "",
      day: "",
      selected: [],
      companynames: [],

      headers: [
        {
          text: "",

          value: "compName",
        },
        { text: "YCC", value: "YCC" },
        { text: "RCC", value: "RCC" },
        { text: "SCC", value: "SCC" },
        { text: "CCC", value: "CCC" },
        { text: "GCC", value: "GCC" },
        { text: "others", value: "others" },
        { text: "Total", value: "" },
      ],
      total: 0,
      others: 0,
      cement: [],
      totalmark: [],
      totalm: [],
      totalSales: 0,
      headersMarketDetails: [
        {
          text: "Name",

          value: "name",
        },

        { text: " Distribtur", value: "transporterName" },
        { text: "Quantity ", value: "activityName" },
        { text: "Area ", value: "locationName" },
        { text: " Date of Update", value: "lastVisit", dataType: "Date" },
        { text: "price", value: "price" },
        { text: "Avg Price", value: "avgPrice" },
        { text: "Total Amount ", value: "totalAmount" },

        { text: "YCC", value: "ycc" },
        { text: "RCC", value: "rcc" },
        { text: "CCC", value: "ccc" },
        { text: "Other", value: "other" },
      ],
      itemMarketDetails: [],
    };
  },

  watch: {
    AreaId: function () {
      this.CompaniesSalesByArea();
    },
    month() {
      this.getTotalMarketSales();
    },
    year() {
      this.getTotalMarketSales();
    },
  },

  created() {
  
    this.getDate();
    this.CompaniesSalesByArea();
    this.getMarketSales();
    // this.getAvgPrice();
    this.getOtherTotalMarketSales();
    this.getTotalMarketSales();
    this.getOtherTotalMarketSales(), this.getTotalSales();
    this.getMarketDetails();
    this.getAreaList();
  },

  methods: {
    getYears() {
      let y = 0;
      while (y <= 5) {
        this.years.push(this.year + y);
        y++;
      }
    },

    CompaniesSalesByArea() {
      this.chartOptions.labels = [];
      // this.Options.labels = [];
      this.AreaData = [];
      this.series = [];
      let labels = [];
      let totalSalesInArea = 0;

      let url = `YearReport/CompaniesSalesByArea?areaId=${this.AreaId.id}&year=${this.year}&month=${this.month}`;
      this.ApiService.get(url).then((res) => {
        let Optionlabels = [];

        res.data.responseData.forEach((el) => {
          this.AreaData.push(el.sales);

          labels.push(el.name);
          // this.AreaData = [12, 15, 12];
        });
        this.chartOptions = { labels: labels };

        this.AreaData.forEach((el) => {
          totalSalesInArea = totalSalesInArea + el;
        });

        this.series.push(totalSalesInArea);
        this.series.push(this.total);
        Optionlabels.push(this.AreaId.name);
        Optionlabels.push("Total Sales");
        this.Options = {
          labels: Optionlabels,
        };
      });
    },
    getDate() {
      var date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.day = date.getDate();
      this.getYears();
    },
    async getAreaList() {
      this.ApiService.get("YearReport/ListAreas").then((res) => {
        res.data.responseData.forEach((el) => {
          this.Allarea.push({ id: el.id, name: el.name });
        });
      });

      // this.Allarea = response.data;
      // this.Allarea.push({ CITY: "total" });
    },

    getTotalMarketSales() {
      let urlYamama = `MarketShare/YamamaTotalSales?year=${this.year}&month=${this.month}`;
      let url = `MarketShare/TotalSalesForEachComp?year=${this.year}&month=${this.month}`;
      this.ApiService.get(urlYamama).then((res) => {
        this.DailyVolume[0].YCC = parseInt(res.data.responseData);
      });
      this.ApiService.get(url).then((res) => {
        res.data.responseData.rows.forEach((ele) => {
          if (ele.r1 == "اسمنت المدينة") {
            this.DailyVolume[0].CCC = parseInt(ele.r3);
          } else if (ele.r1 == "اسمنت الرياض") {
            this.DailyVolume[0].RCC = parseInt(ele.r3);
          } else if (ele.r1 == "اسمنت القصيم") {
            this.DailyVolume[0].GCC = parseInt(ele.r3);
          } else if (ele.r1 == "اسمنت الجنوبية ") {
            this.DailyVolume[0].SCC = parseInt(ele.r3);
          } else {
            this.DailyVolume[0].other =
              this.DailyVolume[0].other + parseInt(ele.r3);
          }
        });
      });
    },
    getMarketDetails() {
      let url = `YearReport/GetCustomersWithProductsDetailsReport?year=${this.year}&month=${this.month}`;
      this.ApiService.get(url).then((res) => {
        let firstitem = [];
        res.data.responseData.forEach((el) => {
          firstitem.push({
            name: el.name,
            transporterName: el.transporterName,
            locationName: el.locationName,
            lastVisit: el.lastVisit,
            avgPrice: el.avgPrice,
            activityName: el.activityName,
            totalAmount: el.totalAmount,
            price: el.price,
          });
        });
        let second = [];
        let ycc = 0;
        let rcc = 0;
        let ccc = 0;
        let other = 0;
        res.data.responseData.forEach((el) => {
          el.cementPerCompanies.forEach((ele) => {
            if (ele.companyName == "اسمنت اليمامة") {
              ycc = ele.quantity;
            } else if (ele.companyName == "اسمنت المدينة") {
              ccc = ele.quantity;
            } else if (ele.companyName == "اسمنت الرياض") {
              rcc = ele.quantity;
            } else {
              other = other + ele.quantity;
            }

            // console.log("secpnd",second)
          });
          second.push({
            ycc: ycc,
            ccc: ccc,
            rcc: rcc,
            other: other,
          });
        });

        //  this.itemMarketDetails=firstitem/

        this.itemMarketDetails = firstitem.map((item, i) =>
          Object.assign({}, item, second[i])
        );
        // console.log("this.itemMarketDetails",this.itemMarketDetails)

        // this.itemMarketDetails= res.data.responseData
      });
    },
    getOtherTotalMarketSales() {
      let url = `MarketShare/OthersTotalSales?year=${this.year}&month=${this.month}`;
      this.ApiService.get(url).then((res) => {
        this.others = res.data.responseData;
      });
    },
    getTotalSales() {
      let url = `MarketShare/TotalSales?year=${this.year}&month=${this.month}`;
      this.ApiService.get(url).then((res) => {
        this.totalSales = res.data.responseData.footer.r3;
      });
    },

    getMarketSales() {
      let url = `MarketShare/MarketShareSales?year=${this.year}&month=${this.month}`;
      this.ApiService.get(url)
        .then((res) => {
          this.MarketShare[0].YCC = res.data.responseData.yamamaTotalSales;
          res.data.responseData.totalSalesForEachComp.forEach((ele) => {
            if (ele.compName == "اسمنت المدينة") {
              this.MarketShare[0].CCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت الرياض") {
              this.MarketShare[0].RCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت القصيم") {
              this.MarketShare[0].GCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت الجنوبية ") {
              this.MarketShare[0].SCC = parseInt(ele.sales);
            } else {
              this.MarketShare[0].other =
                this.MarketShare[0].other + parseInt(ele.sales);
            }
          });
        })
        .catch(() => {});
    },
    getAvgPrice() {
      let url = `YearReport/CompaniesWithAvgPrice?year=${this.year}&month=${this.month}`;
      this.ApiService.get(url)
        .then((res) => {
          this.AvgPrice[0].YCC = res.data.responseData.yamamaTotalSales;
          res.data.responseData.totalSalesForEachComp.forEach((ele) => {
            if (ele.compName == "اسمنت المدينة") {
              this.AvgPrice[0].CCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت الرياض") {
              this.AvgPrice[0].RCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت القصيم") {
              this.AvgPrice[0].GCC = parseInt(ele.sales);
            } else if (ele.compName == "اسمنت الجنوبية ") {
              this.AvgPrice[0].SCC = parseInt(ele.sales);
            } else {
              this.AvgPrice[0].other =
                this.AvgPrice[0].other + parseInt(ele.sales);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.v-btn {
  right: 600px;
}
</style>
